import styles from "./AnalyticsFeatures.module.css";

export const AnalyticsFeatures: React.FC = () => {
  return (
    <section className={styles.analytics}>
      <div className={styles.imageContainer}>
        <img className={styles.img} src="settings.png" alt="" />
        <img className={styles.img} src="table.png" alt="" />
        <img className={styles.img} src="graph.png" alt="" />
      </div>
      <div className={styles.textContainer}>
        <h6 className={styles.preTag}>Stop sweating logistics</h6>
        <h4 className={styles.mainTag}>Focus on the mission</h4>
        <p className={styles.body}>
          By allowing the software to do the heavy lifting, you'll be able to
          focus on the experience you deliver for your participants.
        </p>
      </div>
    </section>
  );
};
