import { Background, FadeMixin } from "./Background/Background";

export const Mountain: React.FC<{ children: React.ReactNode } & FadeMixin> = ({
  children,
  fadeTop = false,
  fadeBottom = false,
}) => (
  <Background
    src="https://images.unsplash.com/photo-1672173038243-757b40f749df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
    alt="A beautiful mountain sunset"
    fadeTop={fadeTop}
    fadeBottom={fadeBottom}
  >
    {children}
  </Background>
);
