import styles from "./Header.module.css";

export const Header: React.FC<{ scrollPos: number }> = ({ scrollPos }) => {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerGroup}>
          <img className={styles.headerImg} src="logo_98x98.png" alt="" />
          <h1 className={styles.name}>Endeavor BigBoard</h1>
        </div>
        <div className={styles.headerGroup}>
          <a className={`${styles.headerLink} ${styles.hideIfSmall}`} href="#features">
            Features
          </a>
          <a
            className={`${styles.headerLink} ${styles.account}`}
            href="https://app.endeavorbigboard.com/login"
          >
            Your Account
          </a>
        </div>
      </header>
      <div
        className={styles.navGradient}
        style={{
          opacity: Math.min(1, scrollPos * 0.005),
        }}
      />
    </>
  );
};
