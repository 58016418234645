import React, { useEffect, useState } from "react";
import "./App.css";
import { Header, Hero } from "./components";
import { AnalyticsFeatures } from "./components/AnalyticsFeatures/AnalyticsFeatures";
import { Mountain, StarrySky } from "./components/Backgrounds";
// import { AnalyticsFeatures } from "./components/AnalyticsFeatures/AnalyticsFeatures";
import { Benefits } from "./components/Benefits/Benefits";
import { Features } from "./components/Features/Features";
import { Footer } from "./components/Footer/Footer";
import { SpaceAfterRotatingImage } from "./components/SpaceAfterRotatingImage/SpaceAfterRotatingImage";
import { initializeFirebase } from "./firebase";
import { UsedBy } from "./components/UsedBy/UsedBy";
// import { UsedBy } from "./components/UsedBy/UsedBy";

initializeFirebase();

function App() {
  const [scrollPos, setScrollPos] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPos(window.scrollY);
    });
  }, []);

  return (
    <div className="App">
      <Header scrollPos={scrollPos} />
      <Mountain fadeBottom>
        <Hero scrollPos={scrollPos} />
      </Mountain>
      <StarrySky fadeTop fadeBottom>
        <SpaceAfterRotatingImage />
        <Benefits />
        <Features />
        <AnalyticsFeatures />
        <UsedBy />
        <Footer />
      </StarrySky>
    </div>
  );
}

export default App;
