import styles from "./Background.module.css";

export type FadeMixin = {
  fadeTop?: boolean;
  fadeBottom?: boolean;
};

export const Background: React.FC<
  {
    children: React.ReactNode;
    src: string;
    alt: string;
    bw?: boolean;
  } & FadeMixin
> = ({
  children,
  src,
  alt,
  fadeBottom = false,
  fadeTop = false,
  bw = false,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <img className={styles.image} src={src} alt={alt} />
        {fadeTop && <div className={styles.gradientTop} />}
        {fadeBottom && <div className={styles.gradientBottom} />}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
