import "./Benefits.css";

export const Benefits: React.FC = () => {
  return (
    <section id="benefits">
      <div className="dynamic-row">
        <div className="dynamic-row-item">
          <h4>Streamline leader management</h4>
          <p>
            Finding the right leaders for your trips can be a challenge. Our
            software makes it easy by allowing you to create and manage a
            database of potential leaders, track their experience and
            certifications, and find the best match for a given assignment.
          </p>
        </div>
        <div className="dynamic-row-item">
          <h4>Detect conflicts early</h4>
          <p>
            Our software makes it easy to identify scheduling conflicts and
            errors before they become a problem. With automated checks and
            real-time updates, you can quickly adjust plans to ensure everything
            runs smoothly. This means fewer headaches, happier participants, and
            a more successful wilderness trip every time.
          </p>
        </div>
        <div className="dynamic-row-item">
          <h4>Stay on top of logistics in real-time</h4>
          <p>
            With our software's real-time updates and notifications, you and
            your staff will always be informed about the latest updates. Adjust
            your organizations decision-making with tailored reports and
            analytics.
          </p>
        </div>
      </div>
    </section>
  );
};
