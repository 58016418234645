import styles from "./Footer.module.css";

const initialYear = 2023;

export const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="container">
      <div className={styles.links}>
        <a
          href="https://www.linkedin.com/company/endeavor-software-co/"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <img
            src="LinkedIn.svg"
            alt="The linkedin logo"
            className={styles.externalLinkIcon}
          />
        </a>
      </div>
      <div className={styles.legal}>
        <div>
          Copyright &copy;{" "}
          {currentYear > initialYear
            ? `${initialYear} – ${currentYear}`
            : currentYear}{" "}
          Endeavor Software Co.
        </div>
        <div>All rights reserved.</div>
      </div>
    </footer>
  );
};
