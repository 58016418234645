// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyApRaAC-rj-x2Sj1Wtzfr3RboLL1V-ub1A",
  authDomain: "bigboard-landing.firebaseapp.com",
  projectId: "bigboard-landing",
  storageBucket: "bigboard-landing.appspot.com",
  messagingSenderId: "865836602714",
  appId: "1:865836602714:web:ab94ab4d16bb415c414f63",
  measurementId: "G-Q9X6JN144N",
};

export const initializeFirebase = () => {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
}
