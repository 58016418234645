import React from "react";
import "./UsedBy.css";

export const UsedBy: React.FC = () => {
  return (
    <section id="usedby-section">
      <h3>Customers</h3>
      <div>
        <img src="widjiwagan.png" alt="YMCA Camp Widjiwagan's Logo" />
        <img src="menogyn.png" alt="YMCA Camp Menogyn's logo" />
        <img src="ymca-logo.png" alt="The YMCA of the North's logo" />
      </div>
    </section>
  );
};
