import { Background, FadeMixin } from "./Background/Background";

export const StarrySky: React.FC<{ children: React.ReactNode } & FadeMixin> = ({
  children,
  fadeBottom = false,
  fadeTop = false,
}) => {
  return (
    <Background
      src="starry.jpeg"
      alt="A starry night"
      fadeTop={fadeTop}
      fadeBottom={fadeBottom}
      bw
    >
      {children}
    </Background>
  );
};
