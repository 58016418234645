import "./Features.css";

import { Perk } from "../Perk/Perk";

export const Features: React.FC = () => (
  <section id="features">
    <h3>Features</h3>
    <div className="container features-columns dynamic-row">
      <div id="features-left">
        <Perk text={"Centralize Logistics Planning"} />
        <Perk text={"Project Staffing Requirements"} />
        <Perk text={"Manage Driving Logistics"} />
        <Perk text={"Detect Conflicts"} />
      </div>
      <div>
        <Perk text={"Powerful Analytics"} />
        <Perk text={"Easy–to–use interface"} />
        <Perk text={"Dependable infrastructure"} />
        <Perk text={"Access anywhere"} />
      </div>
    </div>
  </section>
);
